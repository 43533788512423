.prl_box_top {
  z-index: 9;
  position: relative;

  .orange_prl_box {
    border: 3px solid orange;
    border-radius: 40px;
    z-index: 9;
    margin-top: 70px;
    position: relative;
    padding: 120px;
    text-align: center;
  }

  .prl_box_desc {
    width: 50%;
    float: right;
  }

  .prl_box_bg {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    height: 250px;
    margin-top: 60px;
    z-index: 9999;
  }
}

.prl_box_mod {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.158);
  margin-top: -100px;
  padding: 30px;
  position: relative;
  text-align: center;

  h2 {
    text-align: center;
    color: #F9921F;
    font-size: 40px;
    font-weight: 700;
  }
}

.prl_box_middle {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 80px;

  .btn_orange, .btn_green, .btn_red {
    margin: 0 auto 10px auto;
  }

  .prl_box_mod {
    width: 100%;
    margin-top: -120px;
    z-index: 990;

    p {
      padding: 20px 10px;
      max-width: 100%;
    }

    input[type=submit] {
      margin-top: 100px;
    }
  }
}

.prl_box_middle.no_margin_bottom {
  margin-bottom: 0;
}

.prl_box_bottom {
  z-index: 9;
  position: relative;
  top: -200px;
  margin-bottom: -200px;

  .orange_prl_box {
    border: 3px solid orange;
    border-radius: 40px;
    z-index: 9;
    margin-top: 70px;
    position: relative;
    padding: 120px;
    text-align: center;
  }

  .prl_box_desc {
    width: 50%;
    float: right;
  }

  .prl_box_bg {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    height: 250px;
    position: relative;
    z-index: 999;

  }

  .prl_box_mod {
    bottom: -60px;
  }
}

.prl_box_bottom.no_top {
  top: 0;
}

.prl_box_under_bottom {

  position: relative;
  top: -150px;

  .prl_box_mod {
    width: 100%;
    margin-top: -120px;
    z-index: 999;

    input[type=submit] {
      margin-top: 100px;
    }
  }
}

.prl_box_mod li {
  text-align: left
}