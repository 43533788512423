html {
  scroll-behavior: smooth;
}

a {
  color: #000;
  text-decoration: none;
}

.content_container {
  padding-top: 150px;

  h1, h2 {
    font-size: 63px;
    text-transform: uppercase;
    color: orange;
    text-align: center;
    font-weight: 600;
  }

  h3 {
    font-size: 34px;
    color: orange;
    text-align: center;
  }

  p {
    color: #000;
    margin: 0 auto;
    text-align: justify;
    padding: 5px;
  }
}

.actionBtn {
  margin: 20px;
  cursor: pointer;
  font-size: 30px;
}

.mod_calc h2 {
  margin-top: 0px !important;
}

.text-black-30 {
  color: rgba(0, 0, 0, 0.3) !important
}

.text-black-30:hover {
  color: rgba(0, 0, 0, 1) !important
}

p {
  padding-top: 5px;
  padding-bottom: 15px;
}

.rounded_button {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 40px;
  border-radius: 33px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.rounded_button.active, .rounded_button:hover {
  color: #ffffff;
  background: #F9921F;
}

.diet_days {
  display: inline;
}

.diet_days .rounded_button {
  margin: 0 5px 5px 0;
}

.orange-text {
  color: #F9921F;
}

.diet_generator {
  .diet_menu_item h3 {
    padding: 50px 0;
  }

  .dish_name {
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }

  .btn_orange, .btn_green, .btn_red {
    width: 100%;
    font-size: 13px;
    padding: 20px 20px;
    margin: 5px 0;
  }

  .btn_orange svg, .btn_green svg, .btn_red svg {
    font-size: 24px;
  }
}

.dish_component_name {
  text-transform: uppercase;
}

p.seo_text {
  max-width: 80%;
}

.action-button {
  padding: 5px;
  font-size: 13px;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 8px;
  transition: all 0.4s;
  background: #fff;
  font-weight: 600;
}

.action-button:hover {
  text-decoration: none;
}

.action-button-green {
  border: 1px solid #63953F;
  color: #63953F;
}

.action-button-green:hover {
  color: #fff;
  background-color: #a9e77c;
}


.action-button-orange {
  border: 1px solid #ffc107;
  color: #ffc107;
}

.action-button-orange:hover {
  color: #fff;
  background-color: #ffe007;
}

.action-button-red {
  border: 1px solid #dc3545;
  color: #dc3545;
}

.action-button-red:hover {
  color: #fff;
  background-color: #f57878;
}

.action-button-black {
  border: 1px solid #000;
  color: #000;
}

.action-button-black:hover {
  color: #fff;
  background-color: #2B2B2B;
}

.text-strike-through {
  text-decoration: line-through;
}

.shopping-list-item {
  cursor: pointer;
}

.subscription-item {
  position: relative;
  text-align: center;
  border-radius: 59px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;

  .btn_default {
    margin: 20px auto;
  }
}

.subscription-title {
  font-weight: bold;
  padding: 20px;
  font-size: 30px;
}

.subscription-price {
  position: relative;
  font-weight: bold;
  padding: 20px;
  font-size: 32px;
  color: orange;

  .subscription-price-monthly {
    font-size: 16px;
    color: #000;
  }
}

.promotional-price {
  font-size: 12px;
  color: #777;
}

.subscription-promotional-text-badge, .subscription-promotional-text {
  color: #fff;
  background-color: #dc3545;
  padding: 3px;
  font-weight: bold;
  border-radius: 8px;
}

.subscription-promotional-text-badge {
  position: absolute;
  font-size: 14px;
  transform: rotate(45deg);
  right: 30px;
}

.subscription-promotional-text {
  display: block;
  font-size: 12px;
  top: 10px;
}

.green_cta_modal {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  left: 0;

  button.close-paywall {
  }
}

.subtitle-container {
  margin: auto;
  padding: 20px 0;

  p {
    line-height: 1.4;
    text-align: justify;
    padding-right: 15px;
    padding-left: 15px;
  }

  h2 {
    text-align: center;
    line-height: 1.5;
  }
}

.oauth-button {
  width: 250px;
  text-align: left !important;
  font-size: 13px !important;
  margin-top: 10px !important;

  img {
    margin-right: 30px;
  }
}

.oauth-button.wide {
  width: 300px;
  text-align: center !important;

  img {
    margin-right: 30px;
  }
}

.dropdown a.dropdown-item {
  position: relative;
  text-align: center;
}

.dropdown-menu {
  padding: 5px;
}

.cookieConsent {
  color: #fff;
}

.bg-warning {
  color: #212529;
  background-color: #ffc107 !important;
}

.temp-reg {
  margin: 30px auto 0 auto;
  display: block;
  width: 30%
}

.logo-print {
  display: none;
  text-align: center;
  margin: 10px auto;
}


@media print {
  .no-print {
    display: none !important;
  }

  .logo-print {
    display: block;
  }

  .mobile_top {
    display: none !important;
  }

  .prl_box_mod {
    box-shadow: none !important;
  }

  .prl_box_top, .prl_box_bottom {
    display: none !important;
  }
  /* Add more styles to format the page for printing */
}