.footer {
  background-color: #213502;
  padding-top: 30px;
  padding-bottom: 30px;

  .brand {
    text-align: center;
    margin-bottom: 60px;
  }

  .footer_widget {
    text-align: center;

    h2 {
      text-transform: uppercase;
      color: #F9921F;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      font-size: 15px;
      font-weight: 300;
    }

    ul {
      list-style: none;
      margin-left: 0px;
      padding-left: 0px;

      li {
        display: inline-block;
        padding: 0px 15px 15px 15px;
      }
    }
  }

  .subsc h2 {
    padding-bottom: 30px;
  }

  .subscribe_form {
    input.sub {
      height: 50px;
      width: 60%;
      border: 0px;
      outline: none;
      padding-left: 10px;
      background-color: #D9D9D9;
    }

    input[type=submit] {
      height: 40px;
      width: 50px;

      background-color: transparent !important;
      background-repeat: no-repeat !important;
      border: 0px;
      position: absolute;
      margin-top: 5px;
    }

    span {
      margin-left: 10px;
    }
  }

  .footer_menu {
    margin-top: 30px;
    margin-bottom: 30px;

    ul {
      list-style: none;
      display: flex;

      justify-content: space-between;
      margin-left: 0px;
      padding-left: 0px;
    }

    li {

      padding-left: 10px;
      text-align: center;
    }

    a {
      display: block;
      text-transform: uppercase;
      color: #fff;
      font-size: 20px;
    }
  }

  .copy {
    color: #99CA46;
    margin-top: 50px;
  }
}