// colors

$primary: #122e31;
$secondary: #274144;
$third: #0a8f6f;
$fourth: #f4efee;
$body-color: #111;

$first-font: 'Montserrat';
$second-font: 'Lato';
body {
  font-family: $first-font;

}

html {
  overflow-x: hidden !important;
}

a {
  transition: all 0.4s;
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1600px;
  }
}


.form-control:focus {
  border: 0px !important;
  box-shadow: 0px !important;
}