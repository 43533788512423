.calc1 {
  z-index: 9;
  position: relative;

  .orange_calc {
    border: 3px solid orange;
    border-radius: 40px;
    z-index: 9;
    margin-top: 70px;
    position: relative;
    padding: 120px;
    text-align: center;
  }

  .calc_desc {
    width: 50%;
    float: right;
  }

  .vege_calc {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    height: 250px;
    position: relative;
    margin-top: 60px;
    z-index: 9999;
  }
}

.mod_calc {
  background-color: #fff;
  width: 80%;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.158);
  margin-top: -100px;
  padding: 30px;
  float: right;
  position: relative;
  text-align: center;

  h2 {
    text-align: center;
    color: #F9921F;
    font-size: 40px;
    font-weight: 700;
  }

  p {
    text-align: center;
    text-transform: uppercase;
  }
}

.calc2 {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 80px;

  .btn_orange {
    width: 30%;
    margin: 0 auto;
  }

  .mod_calc {
    margin-top: -120px;
    right: 0;
    z-index: 998;

    p {
      margin-bottom: 100px;
    }

    input[type=submit] {
      margin-top: 100px;
    }
  }
}

.calc3 {
  z-index: 9;
  position: relative;

  .orange_calc {
    border: 3px solid orange;
    border-radius: 40px;
    z-index: 9;
    margin-top: 70px;
    position: relative;
    padding: 120px;
    text-align: center;
  }

  .calc_desc {
    width: 50%;
    float: right;
  }

  .vege_calc {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    height: 350px;
    position: relative;
    margin-top: -40px;
    z-index: 9999;

  }

  .mod_calc {
    bottom: -60px;
  }
}

.desc_bottom {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;

  .left {
    width: 45%;
    text-align: center;

    a.green {
      color: #99CA46;
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 50px;
    }
  }

  .right {
    width: 30%;
    right: 10%;
    position: absolute;
    top: -100px;
    bottom: -50px;
    text-align: center;
    z-index: 999999;
    border: 2px solid orange;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.164);
    padding: 150px 75px 50px 75px;

    .btn_green {
      width: 50%;
      margin-top: 50px;
    }
  }
}

.calc_before_foot {
  background-color: #213502;
  padding-top: 4px;
  margin-top: 150px;

  .calculator {

    padding-top: 0px;

    .main_img {
      margin: -108px auto;
      width: 80%;
      -webkit-background-size: cover !important;
      -moz-background-size: cover !important;
      -o-background-size: cover !important;
      background-size: cover !important;
      border-radius: 50px;
      margin-bottom: 50px;
    }
  }

}