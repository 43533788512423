
.menu-wrap a {
  color: #b8b7ad;
}

.menu-wrap a:hover,
.menu-wrap a:focus {
  color: #c94e50;
}

.content-wrap {

  overflow-y: unset;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.content {
  position: relative;
  background: #b4bad2;
}

/* Overlay */
.content::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  content: '';
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0s 0.3s;
  transition: opacity 0.3s, transform 0s 0.3s;
}

/* Menu Button */
.menu-button {
  position: fixed;
  z-index: 1000;
  padding: 0;
  top: 24px;
  width: 2.5em;
  right: 0;
  height: 2.25em;
  border: none;
  text-indent: 2.5em;
  font-size: 1.5em;
  color: transparent;
  background: transparent;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.menu-button::before {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  bottom: 0.5em;
  left: 0.5em;
  background: url("ham.png");
  background-repeat: no-repeat;
  content: '';
}

.close-button {
  position: fixed;
  z-index: 1000;
  margin: 1em;
  padding: 0;
  width: 2.5em;
  height: 2.25em;
  border: none;
  text-indent: 2.5em;
  font-size: 1.5em;
  color: transparent;
  background: transparent;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.close-button::before {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  bottom: 0.5em;
  left: 0.5em;
  background: linear-gradient(#373a47 20%, transparent 20%, transparent 40%, #373a47 40%, #373a47 60%, transparent 60%, transparent 80%, #373a47 80%);
  content: '';
}

.menu-button:hover {
  opacity: 0.6;
}

/* Menu */
.menu-wrap {
  position: fixed;
  background-color: rgb(247, 247, 247);
  font-weight: 700;
  height: 100vh;
  opacity: 0;
  width: 0px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  top: 70px;
}

.main_nav {
  text-align: center;
}

nav.menu-side.main_nav {
  display: none;
}

.show-menu nav.menu-side.main_nav {
  display: block;
}

nav.menu-side.main_nav ul {
  list-style: none;
  border-bottom: 1px solid #ededed67;
  margin-bottom: 0px;
}

.menu-top {
  line-height: 58px;
}

.menu-top .profile {
  display: inline-block;
  padding: 8px 10px;
  line-height: 42px;
}

.menu-top .profile,
.menu-side {
  width: 300px;
}

.menu-top .profile img {
  float: left;
  margin-right: 1em;
}

.icon-list {
  display: inline-block;
  font-size: 1.25em;
}

.icon-list a {
  margin: 0 1em 0 0;
  padding: 0;
}

@media screen and (max-width: 32em) {
  .icon-list {
    padding-left: 1em;
  }
}

.menu-side a {
  display: block;
  padding-top: 11px;
  padding-bottom: 11px;
  color: #fff;

}

.menu-side a:first-child {

}

/* Shown menu */
.show-menu .menu-wrap {
  opacity: 1;
}

.show-menu .content-wrap,
.show-menu .menu-button {
  -webkit-transform: translate3d(300px, 60px, 0);
  transform: translate3d(300px, 60px, 0);
}

.show-menu .content::before {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.login_mobile li a {
  display: block;
  font-weight: 600 !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

ul.login_mobile {
  padding: 15px;
}

.mobi_icon {
  margin-right: 15px;
}

.logout a {
  color: #fff !important;
}

.logout {
  padding-left: 29%;

}

.main_mobile_menu {
  padding-bottom: 10px;
}

.show-menu #close-button {
  display: inline-block !important;
}

.show-menu .menu-top {
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #ededed67;
}
