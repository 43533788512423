.menu-button {
  display: none;
}

#status {
  width: 200px;
  margin: 0 auto;

  -webkit-animation: bounce 0.5s infinite ease-in-out;
  -o-animation: bounce 0.5s infinite ease-in-out;
  -ms-animation: bounce 0.5s infinite ease-in-out;
  -moz-animation: bounce 0.5s infinite ease-in-out;
  animation: bounce 0.5s infinite ease-in-out;
  margin-top: 20%;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(-5px)
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(-5px)
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-5px)
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(-5px)
  }
}

.menu_top {
  background-color: #213502;
  height: 100px;
  padding-top: 40px;
  position: fixed;
  z-index: 999;
  transition: all 0.4s;
  top: 0;
}

#close-button {

  width: 32px;
  height: 32px;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  border: 0px;


}

.mobile_top {
  display: none;
}

.main_menu {
  ul {
    list-style: none;
    display: flex;

    justify-content: space-between;
    margin-left: 0px;
    padding-left: 0px;
  }

  li {

    padding-left: 10px;
  }

  img {
    max-width: 250px;
    margin-top: -20px;
  }

  a {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;

  }

  a:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

.flags {
  position: relative;
  margin-top: 10px;

  span img:first-child {
    margin-right: 10px;
  }

  .drop {
    position: absolute;
    top: 40px;
    cursor: pointer;
    display: none;
  }

  .arr {
    transition: all 0.4s;
    cursor: pointer;
  }
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.4s;
}

.btn_login {
  border: 1px solid #99CA46;
  background-color: transparent;
  color: #99CA46 !important;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 19px;
  font-weight: 600;
  margin-top: -15px;
}

.container-fluid.menu_top.fixed {
  position: fixed;
  top: 0;
  transition: all 0.4s;
  height: 80px;
  padding-top: 25px;
  z-index: 999;
  width: 100%;
}