.result {
  margin-top: 50px;

  .col-md-3 {
    text-align: center;
  }

  .col-md-6 {
    text-align: center;
  }

  .head {
    margin-bottom: 50px;
    text-align: center;
    width: 100%;
    margin-top: 50px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
  }

  p {
    text-transform: unset;
  }

  .button_cards {
    width: 100%;
    margin-top: 50px;
  }
}