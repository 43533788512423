.home_slider {
  .carousel-item {
    height: 600px;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }

  .carousel-indicators button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    opacity: 1;
    border: 1px solid #213502;
  }

  .carousel-indicators .active {
    background-color: #F9921F !important;
    opacity: 1;
    width: 20px;
    height: 20px;
    margin-top: -4px;
    border: 1px solid #213502;
  }

  .slid_left {
    background-color: rgba(101, 153, 63, 0.562);
    box-shadow: 5px 5px 5px rgba(99, 149, 63, 0.308);
    text-align: center;
    padding-top: 12%;

    h2 {
      font-size: 40px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .btn_white {
    margin: 20px auto -10px auto;
  }
}

.try_icons {
  margin-top: 80px;
  margin-bottom: 80px;

  .ico_block {
    text-align: center;
    margin-top: 80px;

    h2 {
      margin-top: 15px;
      font-size: 37px;
      font-weight: 600;
      font-family: $second-font;
      margin-bottom: 35px;
    }

    p {
      max-width: 68%;
      margin: 0 auto;
    }
  }
}

a.btn_try {
  display: block;
  padding: 15px;
  width: 28%;
  font-size: 25px;
  background-color: #63953F;
  text-align: center;
  color: #fff;
  margin: 100px auto 30px auto;
  font-weight: 600;
  border-radius: 8px;
}

#login_modal a.btn_try {
  font-size: 13px;
  margin: 0px auto 30px auto;
}

a.btn_try:hover {
  text-decoration: none;
  background-color: rgb(124, 190, 77);
}

.cards {
  padding-bottom: 30px;

  .card {
    padding: 50px;
    border-radius: 50px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.288);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.288);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.288);
    max-width: 39%;
    margin: 2%;

    .card_icons {
      margin-top: 20px;
      margin-bottom: 30px;

      .col-md-3 {
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
      }

      p {

        font-size: 12px;
        font-family: $second-font;
        font-weight: 400;
      }
    }

    .card_desc p {
      text-align: center;
      font-weight: 400;
      font-size: 22px;
      font-family: $first-font;
    }
  }

  .mar_le {
    margin-left: 5%;
  }

  .imag {
    height: 300px;
    width: 100%;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    border-radius: 50px;
  }

  .card_icons_img {
    height: 70px;
    width: 80%;
    margin: 0 auto;
    -webkit-background-size: contain !important;
    -moz-background-size: contain !important;
    -o-background-size: contain !important;
    background-size: contain !important;
  }

}

.btn_green {
  border: 1px solid #63953F;
  display: block;
  padding: 10px 10px;
  font-size: 15px;
  text-align: center;
  color: #63953F;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 8px;
}

.btn_green:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: rgb(169, 231, 124);
}

.btn_orange {
  border: 1px solid #F9921F;
  display: block;
  padding: 20px 20px;
  font-size: 15px;
  text-align: center;
  color: #F9921F;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 8px;
}

.btn_orange:hover {
  text-decoration: none;
  background-color: rgb(245, 183, 111);
  color: #ffffff;
}

.btn_red {
  border: 1px solid #dc3545;
  display: block;
  padding: 10px 10px;
  font-size: 15px;
  text-align: center;
  color: #dc3545;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 8px;
}

.btn_red:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #dc3545;
}

.btn_blue {
  border: 1px solid #007bff;
  display: block;
  padding: 10px 10px;
  font-size: 15px;
  text-align: center;
  color: #007bff;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 8px;
}

.btn_blue:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #5b99dc;
}

.carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 50%;
  bottom: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #345;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  height: 30px;
}

.try {
  height: 100vh;

  width: 100%;
  padding-top: 10%;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  position: relative;

  .btn_white {
    margin-top: 15px;
  }

  .green_back {
    width: 100%;
    height: 70vh;
    -webkit-background-size: contain !important;
    -moz-background-size: contain !important;
    -o-background-size: contain !important;
    background-size: contain !important;
    text-align: center;
    padding-top: 25vh;

    h2 {
      font-size: 3vh;
      max-width: 80%;
      color: #fff;
      font-weight: 600;
      margin: 0 auto;
      font-family: $first-font;
      margin-bottom: 20px;
    }
  }
}

.cards_mobile {
  display: none;

  .carousel-indicators {
    bottom: -25px;

    li {
      width: 15px;
      height: 15px;
      background-color: green;
      border-radius: 50%;
    }

  }
}

.btn_white {
  border: 1px solid #fff;
  display: block;
  padding: 15px 80px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  margin: 5px auto;
  font-weight: 600;
  border-radius: 8px;
  width: fit-content;
  background: transparent;
}

.btn_white.close-paywall {
  border: 1px solid #fff;
  display: block;
  padding: 5px 40px;
  font-size: 13px;
  text-align: center;
  color: #fff;
  margin: 20px auto 5px auto;
  font-weight: 400;
  border-radius: 4px;
  width: fit-content;
  background: transparent;
}

.btn_white:hover {
  text-decoration: none;
  background-color: rgb(245, 183, 111);
  color: #fff;
}

.bottom_section {
  width: 100%;
  max-width: 1700px;
  border: 3px solid #F9921F;
  border-radius: 50px;
  margin: 0 auto;
  padding-bottom: 40px;
  padding-top: 100px;
  margin-top: -50px;
  position: relative;
  z-index: 99999;

  .main_img {
    width: 65%;
    margin: 0 auto;
    height: 800px;
    -webkit-background-size: contain !important;
    -moz-background-size: contain !important;
    -o-background-size: contain !important;
    background-size: contain !important;
  }

  h2 {
    font-size: 23px;
    color: #fff;
    text-align: center;
  }

  p {
    color: #fff;
    text-align: center;
    max-width: 45%;
    margin: 0 auto;
    font-weight: 300;
    margin-bottom: 20px;
  }
}

.before_footer {
  background-color: #213502;
  color: #fff;
  position: relative;

  text-align: center;
  margin-top: -50%;
  padding-top: 50%;

}

#login_modal {
  padding-right: 0px !important;
  background-color: rgba(255, 255, 255, 0.068);
  z-index: 999999;

  .modal-dialog {
    max-width: 70%;

  }
}

.modal_open {
  padding-right: 0px !important;
}

#login_modal .close {
  font-size: 53px;
  position: absolute;
  right: 20px;
  top: 7px;
  z-index: 999999;

  span {
    color: #000;
  }

}

#login_modal .modal-body {
  text-align: center;
  padding-bottom: 50px;
}

#login_modal .modal-body h2 {

  text-align: center;
  margin-top: 25px;
  font-size: 25px;
  font-weight: 600;

}

#login_modal .modal-body input[type=submit]:hover {
  background-color: rgb(223, 223, 223);
}

a.forgot {
  color: #99CA46;
  display: block;
  margin-top: 20px;
}

.btn {
  display: block;
  padding: 15px 30px;
  width: 30%;
  font-size: 20px;
  text-align: center;
  margin: 20px auto 20px auto;
  font-weight: 600;
  border: 1px solid;
}

.btn-fb {
  color: rgb(48, 112, 177);
  border-color: rgb(48, 112, 177);
  position: relative;
  padding-left: 7%;
}

.btn-fb::before {
  content: url('http://next-move-dev.pl/dieterian/img/fb2.png');
  position: absolute;
  left: 14%;
  top: 17px;
}

.btn-green {
  color: #63953F;
  border-color: #63953F;
}

.modal-open {
  padding-right: 0px !important;
}

.dropdown {
  margin-left: -15px;
  margin-top: -15px;
}

.drop_account {
  background-color: transparent !important;
  border: 0px;
  box-shadow: 0px;
  color: rgb(255, 174, 23);
  font-weight: 400;
  font-size: 16px;
  margin-left: -8px;
  margin-top: 4px;
  outline: 0px !important;
  display: block;
  width: 100% !important;
}

.drop_account:hover {
  background-color: transparent !important;
}

a.dropdown-item {
  color: #000 !important;
  font-size: 16px !important;
}

.content_container h2 {
  margin-bottom: 20px;
}

.btn_default {
  border: 1px solid #fff;
  display: block;
  padding: 15px 80px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 8px;
  background-color: #f5b76f;
}

.btn_default:hover {
  border: 1px solid #f5b76f;
  color: #f5b76f;
  background-color: #fff;
}