@media (max-width: 1800px) {
  .dropdown {
    margin-top: -20px;
  }
  .main_menu a {
    font-size: 16px;
  }
  .btn_orange {
    font-size: 13px;
  }
  .btn_green {
    font-size: 13px;
  }
  .cards .card .card_icons .col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mod_calc h2 {
    font-size: 30px;
  }

  .prl_box_mod h2 {
    font-size: 30px;
  }

  .content_container {
    padding-top: 150px;
  }
}

@media (max-width: 1400px) {
  .diet_days .rounded_button {
    font-size: 16px;
  }

  .content_container {
    padding-top: 50px;
  }

  .result {
    padding: 15px;
    margin-top: 20px;

    .col-md-3 {
      text-align: center;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-md-6 {
      text-align: center;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .head {
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      margin-top: 20px;

      h2 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }

    p {
      text-transform: unset;
    }

    .button_cards {
      width: 100%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;

      .col-md-6 {
        margin-top: 15px;
      }
    }
  }
  .desc_bottom .right p {
    font-size: 13px;
  }
  .right .btn_green {
    padding: 20px 10px;
  }
  .main_menu a {
    font-size: 16px;
  }
  .menu_top {
    background-color: #213502;
    height: 120px;
    padding-top: 50px;
    position: relative;
    transition: all 0.4s;
    top: 0;
  }
  .home_slider .slid_left h1 {
    font-size: 40px;
  }
  .cards .card .card_icons .col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .cards .card .card_desc p {
    font-size: 16px;
  }
  .btn_orange {
    font-size: 13px;
  }
  .btn_green {
    font-size: 13px;
  }
  .try .green_back h2 {
    font-size: 30px;
    max-width: 80%;
    color: #fff;
    font-weight: 600;
    margin: 0 auto;
    font-family: "Montserrat";
    margin-bottom: 10px;
  }
  .mod_calc h2 {
    font-size: 25px;
  }
  .prl_box_mod h2 {
    font-size: 25px;
  }
}

@media (max-width: 1240px) {
  .show-menu .content-wrap {
    transform: translate3d(-300px, 60px, 0);
  }

  .content_container {
    padding-top: 120px;
  }

  .mobile_top {
    display: block;
  }

  .menu-button {
    display: block;
  }

  .menu_top {
    display: none;
  }

  .mobile_top {
    background-color: #213502;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 90px;
    z-index: 1000;
  }

  .show-menu .menu-wrap {
    background-color: #213502;
    width: 300px;
    right: 0;

    height: 110%;
    overflow-y: scroll;
  }
  #close-button {
    display: none;
    margin-left: 40px;
    right: 14px;
    position: fixed;
    top: 35px;
  }
  nav.menu-side.main_nav {
    background-color: #213502;
    height: 100vh;
    width: 100%;
  }

  .logo_mobile {
    max-width: 150px;
    position: fixed;
    left: 20px;
    top: 28px;

  }
  nav.menu-side.main_nav ul {
    text-align: center;
    padding-left: 0px;
  }

  nav.menu-side.main_nav ul li a {
    font-weight: 400;
    font-size: 15px;
    text-transform: uppercase;
  }
  .lang_mobile {
    display: none;
    width: 40%;
    position: fixed;
    left: 20px;
    top: 25px;
    font-weight: 600;
    text-transform: uppercase;
    color: #99CA46;
  }

  .green_cta_modal {
    button.close {
      top: 115px;
    }
  }
}


@media (max-width: 1024px) {
  .oauth-button {
    width: 200px;

    img {
      width: 32px;
      margin-right: 15px;
    }
  }
  .show-menu .content-wrap {
    transform: translate3d(-300px, 60px, 0);
  }
  .desc_bottom .right {
    font-size: 12px;
    padding: 20px;
    padding-top: 200px;
  }

  .btn {
    font-size: 12px;
  }
  .mobile_top {
    display: block;
  }
  .try_icons .ico_block h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .try_icons .ico_block {
    margin-top: 20px;
  }
  .try_icons {
    margin-bottom: 30px;
  }
  .main_menu a {
    font-size: 10px;
  }
  body {
    overflow-x: hidden;
  }
  .home_slider .container, .home_slider .row {
    margin: 0;
    padding: 0;
  }
  .home_slider {
    margin-top: 90px;
  }
  .home_slider .carousel-item {
    height: 30vh;
  }
  .home_slider .slid_left h1 {
    font-size: 20px;
  }
  .home_slider .carousel-item .row {
    display: block;
  }
  .home_slider .slid_left {
    position: absolute;
    bottom: 7vh;
    width: 100%;
    padding: 10px 30px;
    max-width: 100%;
    height: auto !important;
  }
  .try_icons .ico_block p {
    max-width: 90%;
  }
  .ico_head {
    display: flex;
    justify-content: center;

    img {
      max-height: 30px;
      margin-top: 12px;
      margin-right: 15px;
    }

    h2 {
      font-size: 12px;
    }
  }
  .try_icons {
    margin-top: 0px;
  }
  .cards .card_icons_img {

    height: 40px;
  }
  .card .carousel-item .row {
    display: flex;
  }
  a.btn_try {
    display: block;
    padding: 10px 50px;
    width: fit-content;
    font-size: 25px;
    background-color: #63953F;
    text-align: center;
    color: #fff;
    margin: 20px auto;
    font-weight: 600;
    border-radius: 8px;
    font-size: 14px;
  }
  .before_footer {
    background-color: #213502;
    color: #fff;
    position: relative;
    z-index: -1;
    text-align: center;
    margin-top: -400px;
    padding-top: 410px;
  }

  .try {
    .green_back {
      padding-top: 29vh;
      margin-top: -190px;
    }
  }

  .green_back {
    .btn_white {
      border: 1px solid #fff;
      display: block;
      padding: 8px 16px;
      font-size: 14px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      border-radius: 8px;
      width: fit-content;
    }
  }
  .bottom_section .btn_white {
    border: 1px solid #fff;
    display: block;
    padding: 15px 51px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    font-weight: 600;
    border-radius: 8px;
    width: fit-content;
  }
  .cards .card .card_icons .col-3 {
    padding: 10px;
  }
  .cards .card .card_icons p {
    font-size: 8px;
  }
  .footer .footer_menu ul {
    display: block;
  }
  .try .green_back h2 {
    font-size: 14px;
  }
  .bottom_section p {
    color: #fff;
    font-size: 12px;
    max-width: 100%;
    padding: 10px;
  }
  .bottom_section h2 {
    color: #fff;
    font-size: 16px;
    max-width: 100%;
    padding: 10px;
  }
  .try {
    height: 470px;
    margin-top: 30px;
  }
  .try.green_cta_modal {
    height: 100%;
    margin-top: 0;
  }
  .bottom_section .main_img {
    height: 230px;
    width: 90%;
    margin-top: 20px;
  }
  .bottom_section {
    padding-top: 0px;
    margin-top: 20px;
    max-width: 90%;
  }

  .cards .card {
    max-width: 90%;
    box-shadow: none;
    padding: 25px;
    margin: 20px auto;
  }
  .cards .imag {
    height: 111px;
    width: 100%;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    border-radius: 20px;
  }

  .card {
    text-align: center;
  }
  .cards .card .card_desc p {
    font-size: 16px;
  }
  .carousel-indicators {
    bottom: -10px;
  }

  .btn_green {
    border: 1px solid #63953F;
    display: block;
    padding: 10px 10px;
    text-align: center;
    color: #63953F;
    margin: 0 auto;
    font-weight: 600;
    border-radius: 8px;
  }
  .btn_orange {
    border: 1px solid #F9921F;
    display: block;
    padding: 10px 10px;
    text-align: center;
    color: #F9921F;
    margin: 0 auto;
    font-weight: 600;
    border-radius: 8px;
  }
  #status {
    margin-top: 50%;
    max-width: 150px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .footer_menu ul li a {
    padding-top: 15px;
  }
  .footer .subsc h2 {
    padding-bottom: 5px;
  }
  .footer .subscribe_form input.sub {
    width: 75%;
    margin-left: -10%;
  }
  .result {
    padding: 15px;
    margin-top: 20px;

    .col-md-3 {
      text-align: center;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-md-6 {
      text-align: center;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .head {
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      margin-top: 20px;

      h2 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }

    p {
      text-transform: unset;
    }

    .button_cards {
      width: 100%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;

      .col-md-6 {
        margin-top: 15px;
      }
    }
  }

  .mod_calc h2 {
    font-size: 15px;
  }

  .prl_box_mod h2 {
    font-size: 15px;
  }

  .prl_box_top .prl_box_bg {
    margin-top: 20px;
  }

  .prl_box_middle {
    padding-top: 20px;
  }

  .diet_generator .diet_menu_item h3 {
    padding: 20px 0;
  }

  .prl_box_middle .prl_box_mod p {
    padding: 10px
  }

  .green_cta_modal {
    h2 {
      font-size: 14px !important;
    }

    button.close {
      top: 10px;
      right: 20px;
      font-size: 24px
    }
  }

  .home_slider .slid_left h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .diet_days .rounded_button {
    padding: 10px;
    font-size: 12px;
  }
  .result {
    padding: 15px;
    margin-top: 20px;

    .col-md-3 {
      text-align: center;

    }

    .col-md-6 {
      text-align: center;
    }

    .head {
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      margin-top: 20px;

      h2 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }

    p {
      text-transform: unset;
    }

    .button_cards {
      width: 100%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;

      .col-md-6 {
        margin-top: 15px;
      }
    }
  }
  .calc1 {
    .vege_calc {
      padding: 10px;
      height: 100px;
    }

    .orange_calc {
      padding: 20px;
      z-index: 9999999;
      width: 87%;
      margin: 0 auto;
    }
  }
  .prl_box_top .prl_box_bg {
    padding: 10px;
    height: 50px;
  }
  .content_container h2 {
    font-size: 20px;
  }
  .content_container h3 {
    font-size: 16px;
  }
  .content_container p {
    max-width: 90%;
  }
  .prl_box_top .orange_prl_box {
    padding: 20px;
    z-index: 9999999;
    width: 87%;
    margin: 0 auto;
  }
  .calc3 {
    .vege_calc {
      height: 100px;
    }

    .mod_calc {
      bottom: -100px;
    }
  }
  .prl_box_bottom .prl_box_mod {
    bottom: -100px;
  }
  .calc1 .container {
    padding: 0px;
  }
  .prl_box_top .container {
    padding: 0px;
  }
  .mod_calc {
    position: relative;
  }
  .prl_box_mod {
    position: relative;
  }
  .desc_bottom .left {
    width: 100%;
  }
  .calc2 {
    margin-bottom: 10px;

    .mod_calc {
      margin-top: 10px;
    }
  }
  .prl_box_middle {
    margin-bottom: 10px;
  }
  .prl_box_middle .prl_box_mod {
    margin-top: 10px;
  }
  .desc_bottom .right {
    position: relative;
    right: auto;
    top: 0;
    padding: 20px;
    width: 100%;
  }
  .calc_before_foot {
    margin-top: 10px;
  }
  .mod_calc {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    margin-top: 50px;
    padding: 20px;
  }
  .prl_box_mod {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    margin-top: 50px;
    padding: 20px;
  }
  .calc2 .mod_calc {
    width: 95%;
  }
  .prl_box_middle .prl_box_mod {
    width: 95%;
  }
  .mod_calc h2 {
    font-size: 20px;
  }
  .prl_box_mod h2 {
    font-size: 20px;
  }
  .show-menu .content-wrap {
    transform: translate3d(-80%, 60px, 0);
  }
  .show-menu .menu-wrap {
    background-color: #213502;
    z-index: 9999999;
    width: 80%;
  }
  ul.login_mobile {
    padding-bottom: 10px;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 0px !important;
  }
  .cards_mobile {
    display: block;
  }
  .no_mob {
    display: none;
  }
  .try {
    .green_back {
      padding-top: 28vh;

      h2 {
        margin-top: 20px;
        font-size: 11px;
      }
    }
  }
  .green_cta_modal h2 {
    font-size: 12px !important;
  }
  .try.hp {
    height: 400px;
  }
  .green_back .btn_white {
    font-size: 8px;
  }
  .btn_white.close-paywall {
    margin-top: 10px;
  }
  .before_footer {
    background-color: #213502;
    color: #fff;
    position: relative;
    z-index: -1;
    text-align: center;
    margin-top: -600px;
    padding-top: 610px;
  }

  .menu-side a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .home_slider .slid_left {
    bottom: 3vh;
  }

  .subtitle-container {
    margin: auto;
    padding: 5px 5px;

    h2 {
      text-align: center;
      font-size: 18px;
    }
  }

  .home_slider {

    .slid_left {
      bottom: 0;

      h2 {
        font-size: 15px;
        line-height: 1.3;
      }
    }

    .btn_white {
      margin: 20px auto -10px auto;
      padding: 10px 60px
    }
  }
}

@media (max-width: 500px) {
  .temp-reg {
    width: auto
  }

  .content_container p {
    text-align: left;
  }

  .content_container p label {
    text-align: left;
  }
  .diet_generator .btn {
    width: auto;
  }

  .content_container h1, .content_container .h1, .content_container h2, .content_container .h2 {
    font-size: 32px;
  }

  .subscription-item {
    margin-bottom: 20px;
  }

  .try.hp {
    height: 255px;
  }

  .try {
    .green_back {
      padding-top: 29vh;
      margin-top: -240px;

      .btn_white {
        padding: 4px 8px;
        margin-top: 10px;
      }
    }
  }
}
