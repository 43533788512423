@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,800;0,900;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;0,900;1,400&display=swap');

@import "~bootstrap/scss/bootstrap";
@import "components/custom";
@import "components/mobile_menu";

@import 'components/style';
@import 'components/home';
@import 'components/calculators';
@import 'components/parallax_box';
@import 'components/header';
@import 'components/results';
@import 'components/footer';
@import 'components/responsive';

